import '@quasar/extras/material-icons/material-icons.css';
import 'quasar/src/css/index.sass';

import { Quasar, Notify, LocalStorage } from 'quasar';

const config = {
  plugins: { Notify, LocalStorage }
};

export const install = (app: any) => {
  app.use(Quasar, config);
};
