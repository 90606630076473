import { api } from 'src/modules/axios';

export const loginApi = {
  login: async (userLoginInfo: any) => await api.post(`/api/v1/login`, userLoginInfo),
  getQrCode: async (username: any) => await api.get(`/api/v1/getQrCode?username=${username}`),
  verifyAccount: async (userLoginInfo: any) =>
    await api.post(`/api/v1/verifyAccount`, userLoginInfo),
  setNewPassword: async (setNewPassword: any) =>
    await api.post(`/api/v1/setNewPassword`, setNewPassword)
};
