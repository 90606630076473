<script setup lang="ts">
import { ref } from 'vue';
import { loginApi } from 'src/api/login';
import router from 'src/router';
import { showSnackBar } from 'src/modules/notify';

const password = ref<string>('');
const verificationCode = ref<string>('');
const qrcode = ref<string>('');
const isDisplayPassword = ref<boolean>(false);
const rule = {
  required: (value: any) => !!value || 'This field is required',
  requiredWithoutMessage: (value: any) => !!value,
  passwordLength: (value: string | any[]) =>
    (value.length >= 8 && value.length <= 15) || 'Password should be between 8-15 characters.'
};
const activeForm = ref(null);

const activeAccount = async () => {
  activeForm.value.validate().then(async (success: boolean) => {
    if (success) {
      const response = await loginApi.setNewPassword({
        username: localStorage.getItem('username'),
        password: password.value,
        authcode: verificationCode.value
      });
      if (response.data.status === 2 && response.data.errorCode === '010') {
        showSnackBar('fail', 'Activate failed, please check your verification code.');
      } else if (response.data.status === 1) {
        localStorage.removeItem('username');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        showSnackBar('success', 'Your account has been activated. Please login again.');
        router.push('/login');
      } else {
        showSnackBar('fail', 'Unexpected error.');
      }
    }
  });
};

const get2FaQrCode = async () => {
  const qrCode = await loginApi.getQrCode(localStorage.getItem('username'));
  qrcode.value = qrCode.data.data;
};

get2FaQrCode();
</script>

<template>
  <div class="background">
    <q-form ref="activeForm" class="login-form">
      <div class="logo"><img src="../images/logo_horizontal.png" alt="logo" /></div>
      <div class="divider-border"></div>
      <div class="container">
        <p class="step-title">Step 1 : Apply Google Authention</p>
        <div style="margin: 0px 0px 0px 15px">
          <ul class="instruction">
            <li class="instruction-list">
              Install the Google Authenticator app from your mobile device's app store
            </li>
            <li class="instruction-list">Set up a new account using the QR code</li>
          </ul>
        </div>
        <img :src="qrcode" />
        <p class="step-title">Step 2 : Set New Password</p>
        <div style="margin: 0px 0px 0px 15px">
          <ul class="instruction">
            <li class="instruction-list">Length should be 8-15 characters</li>
          </ul>
        </div>
        <q-input
          class="login-textbox"
          outlined
          placeholder="New Password"
          dense
          minlength="8"
          maxlength="15"
          :type="isDisplayPassword ? 'text' : 'password'"
          @keyup.enter="userLogin"
          :rules="[rule.requiredWithoutMessage, rule.passwordLength]"
          v-model="password"
        >
          <template v-slot:append>
            <q-icon
              :name="isDisplayPassword ? 'visibility' : 'visibility_off'"
              class="cursor-pointer"
              @click="isDisplayPassword = !isDisplayPassword"
            ></q-icon>
          </template>
        </q-input>
        <p class="step-title">Step 3 : Input Verification Code</p>
        <q-input
          class="login-textbox"
          outlined
          placeholder="Verification Code"
          dense
          v-model="verificationCode"
          maxlength="6"
          :rules="[rule.requiredWithoutMessage]"
          v-if="!isFirstLogin"
        ></q-input>
        <q-btn class="login" label="activate" @click="activeAccount"></q-btn>
      </div>
    </q-form>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.background {
  width: 100%;
  height: 100vh;
  background: url('../images/footer_bc.jpg') 100%;
}
.login-form {
  width: 350px;
  height: 650px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 10px 20px 50px 10px rgb(0 0 0 / 20%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.logo img {
  width: 150px;
  height: 50px;
}
.container {
  width: 100%;
  max-width: 1280px;
  height: 100%;
  margin-top: 16px;
}

.login-textbox {
  width: 90%;
  margin: 5px;
  display: inline-block;
  box-sizing: border-box;
}
.login {
  font-size: 13px;
  width: 90%;
  margin-top: 5px;
  padding: 7px;
  border-radius: 0.25rem;
  color: #fff;
  border: none;
  cursor: pointer;
  background-color: #52b480;
}
.divider-border {
  width: 90%;
  height: 1px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 70px;
  left: 0;
  bottom: 0;
  right: 0;
}
.verification-reminder {
  padding-top: 15px;
}
.verification-link {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}
.verification-link:hover {
  color: blue;
  text-decoration: underline;
}
.instruction {
  text-align: left;
  padding: 0 20px;
}
.instruction-list {
  list-style: disc;
}
.step-title {
  text-align: left;
  font-weight: bold;
  padding: 0 20px;
}
</style>
