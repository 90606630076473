import { api } from 'src/modules/axios';

export const paymentStatusApi = {
  getBankStatus: async () => await api.get(`/api/v1/PaymentStatus/bank-status`),
  getPaymentMethodStatus: async () => await api.get(`/api/v1/PaymentStatus/payment-method-status`),
  patchPaymentSetting: async (obj: any) => await api.patch(`/api/v1/paymentStatus/setting`, obj),
  getPaymentMethodBank: async (obj: any) =>
    await api.get(
      `/api/v1/payment-method-bank?PaymentMethod=${obj.PaymentMethod}&Currency=${obj.Currency}&TransactionType=${obj.TransactionType}&ServiceProviderPaymentMethodCode=${obj.ServiceProviderPaymentMethod}`
    ),
  getRelatedPaymentMethodByBank: async (obj: any) =>
    await api.get(`/api/v1/paymentStatus/other-same-bank-payment-method`, obj)
};
