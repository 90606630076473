<script setup lang="ts">
import { ref } from 'vue';
import { loginApi } from 'src/api/login';
import { showSnackBar } from 'src/modules/notify';
import router from 'src/router';
import jwtDecode, { JwtPayload } from 'jwt-decode';

const userName = ref<string>('');
const password = ref<string>('');
const verificationCode = ref<string>('');
const isFirstLogin = ref<boolean>(false);
const isDisplayPassword = ref<boolean>(false);
const token = <string>localStorage.getItem('access_token');
const permission = token === null ? '' : jwtDecode<JwtPayload>(token).pms;

if (localStorage.getItem('access_token') && permission === '500000') {
  router.push('/');
}

const userLogin = async () => {
  const isFinishVerification = !isFirstLogin.value && verificationCode.value;
  if (!userName.value || !password.value || !isFinishVerification) {
    showSnackBar('failed', 'Username, password and verification code are required');
    return;
  }

  const params = new URLSearchParams();
  params.append('client_id', 'fatty-provider-api');
  params.append('grant_type', 'password');
  params.append('username', userName.value);
  params.append('password', password.value);
  params.append('type', 'login');
  params.append('authCode', verificationCode.value);
  const response = await loginApi.login(params);

  if (
    response.data &&
    response.data.error === 'IncorrectAccountOrPassword' &&
    !response.data.access_token
  ) {
    showSnackBar('fail', 'Login failed, please check your login name and password');
  } else if (
    response.data &&
    response.data.error === 'Google2FaValidationFail' &&
    !response.data.access_token
  ) {
    showSnackBar('fail', 'Login failed, please check your verification code.');
  } else if (
    response.data &&
    response.data.error === 'UserIsInactive' &&
    !response.data.access_token
  ) {
    showSnackBar('fail', 'Login failed, please activate your account.');
  } else if (response.data && response.data.error && !response.data.access_token) {
    showSnackBar('fail', 'Unexpected error.');
  } else {
    localStorage.setItem('access_token', response.data.access_token);
    localStorage.setItem('refresh_token', response.data.refresh_token);

    router.push('/');
  }
};
const switchToActivate = async () => {
  isFirstLogin.value = !isFirstLogin.value;
};
const switchToLogin = async () => {
  isFirstLogin.value = false;
};
const activate = async () => {
  if (!userName.value || !password.value) {
    showSnackBar('failed', 'Username and password required');
    return;
  }

  const params = new URLSearchParams();
  params.append('client_id', 'fatty-provider-api');
  params.append('grant_type', 'password');
  params.append('username', userName.value);
  params.append('password', password.value);
  params.append('type', 'active');
  const response = await loginApi.verifyAccount(params);

  if (
    response.data &&
    response.data.error === 'IncorrectAccountOrPassword' &&
    !response.data.access_token
  ) {
    showSnackBar('fail', 'Please check your login name and password');
  } else if (
    response.data &&
    response.data.error === 'UserIsActive' &&
    !response.data.access_token
  ) {
    showSnackBar('fail', 'Your account has been activated');
  } else {
    localStorage.setItem('username', userName.value);
    localStorage.setItem('access_token', response.data.access_token);
    localStorage.setItem('refresh_token', response.data.refresh_token);

    router.push('/active');
  }
};
</script>

<template>
  <div class="background">
    <form class="login-form">
      <div class="logo"><img src="../images/logo_horizontal.png" alt="logo" /></div>
      <div class="divider-border"></div>
      <div class="container">
        <q-input
          class="login-textbox"
          outlined
          placeholder="Username"
          dense
          @keyup.enter="userLogin"
          v-model="userName"
        ></q-input>
        <q-input
          class="login-textbox"
          outlined
          placeholder="Password"
          dense
          :type="isDisplayPassword ? 'text' : 'password'"
          @keyup.enter="userLogin"
          v-model="password"
        >
          <template v-slot:append>
            <q-icon
              :name="isDisplayPassword ? 'visibility' : 'visibility_off'"
              class="cursor-pointer"
              @click="isDisplayPassword = !isDisplayPassword"
            ></q-icon>
          </template>
        </q-input>
        <q-input
          class="login-textbox"
          :style="{ visibility: isFirstLogin ? 'hidden' : 'visible' }"
          outlined
          placeholder="Verification Code"
          dense
          v-model="verificationCode"
          maxlength="6"
          v-if="!isFirstLogin"
        ></q-input>
        <q-btn class="login" label="activate" @click="activate" v-if="isFirstLogin"></q-btn>
        <q-btn class="login" label="login" @click="userLogin" v-else></q-btn>

        <div class="verification-reminder" v-if="isFirstLogin">
          <div>Already activated?</div>
          <div>
            Go to
            <a href="javascript:void(0);" @click="switchToLogin" class="verification-link">
              login page
            </a>
          </div>
        </div>
        <div class="verification-reminder" v-else>
          No verification code?
          <div>
            Go to
            <a href="javascript:void(0);" @click="switchToActivate" class="verification-link">
              activate your account
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.background {
  width: 100%;
  height: 100vh;
  background: url('../images/footer_bc.jpg') 100%;
}
.login-form {
  width: 350px;
  height: 375px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 10px 20px 50px 10px rgb(0 0 0 / 20%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.logo img {
  width: 150px;
  height: 50px;
}
.container {
  width: 100%;
  max-width: 1280px;
  height: 100%;
  margin-top: 16px;
}

.login-textbox {
  width: 90%;
  margin: 5px;
  display: inline-block;
  box-sizing: border-box;
}
.login {
  font-size: 13px;
  width: 90%;
  margin-top: 5px;
  padding: 7px;
  border-radius: 0.25rem;
  color: #fff;
  border: none;
  cursor: pointer;
  background-color: #52b480;
}
.divider-border {
  width: 90%;
  height: 1px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 70px;
  left: 0;
  bottom: 0;
  right: 0;
}
.verification-reminder {
  padding-top: 15px;
}
.verification-link {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}
.verification-link:hover {
  color: blue;
  text-decoration: underline;
}
</style>
