import 'nprogress/nprogress.css';
import axios from 'axios';
import NProgress from 'nprogress';
import { showSnackBar } from 'src/modules/notify';
import router from 'src/router';

const api = axios.create({
  baseURL: import.meta.env.VITE_FATTYAPIGW_URL,
  timeout: 10000
});

api.interceptors.request.use(
  req => {
    const token = localStorage.getItem('access_token');

    if (req.headers && token) {
      req.headers['Authorization'] = `Bearer ${token}`;
    }
    return req;
  },
  error => {
    NProgress.done(true);
    showSnackBar('fail', 'Unexpected error occurred, please inform support for help.');
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  res => {
    NProgress.done(true);
    return res;
  },
  error => {
    NProgress.done(true);

    if (error.response.status === 401) {
      if (localStorage.getItem('access_token')) {
        showSnackBar('fail', 'Your login is expired, please login again');
      }
      logout();
    } else if (error.response.status === 403) {
      showSnackBar('fail', 'Unauthorized IP address, please find FattyPay support.');
      logout();
    } else if (error.response.status.toString().startsWith('5')) {
      showSnackBar('fail', 'Unexpected error, please find FattyPay support.');
    }

    return error.response;
  }
);
const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  router.push('/login');
};

export { api, axios };
