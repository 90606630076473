import { Notify } from 'quasar';

export const showSnackBar = (type: string, message: string) => {
  if (type === 'success') {
    Notify.create({
      type: 'positive',
      color: 'green-1',
      textColor: 'green-8',
      message: message,
      position: 'top',
      timeout: 1000,
      badgeStyle: 'opacity: 0'
    });
  } else {
    Notify.create({
      type: 'negative',
      color: 'red-2',
      textColor: 'red-8',
      message: message,
      position: 'top',
      timeout: 5000,
      badgeStyle: 'opacity: 0'
    });
  }
};
