import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import PaymentStatusSetting from 'src/views/PaymentStatusSetting.vue';
import HelloWorld from 'src/views/HelloWorld.vue';
import NotFound from 'src/views/NotFound.vue';
import UserLogin from 'src/views/UserLogin.vue';
import ActiveAccount from 'src/views/ActiveAccount.vue';

const routes: RouteRecordRaw[] = [
  { path: '/', component: PaymentStatusSetting },
  { path: '/hello-world', component: HelloWorld },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
  { path: '/login', component: UserLogin },
  { path: '/active', component: ActiveAccount }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
