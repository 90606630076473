<script setup lang="ts">
import { ref, reactive } from 'vue';
import { paymentStatusApi } from 'src/api/paymentStatus';
import router from 'src/router';
import { showSnackBar } from 'src/modules/notify';

const ShowRelatedPaymentMethod = ref(false);

const title = import.meta.env.VITE_APP_TITLE;
const tab = ref<string>('payment-methods');

type RelatedPaymentMethod = {
  paymentMethodName: string;
  providerPaymentMethodCode: string;
  bankName: string;
  bankCode: string;
  providerBankCode: string;
  paymentMethodDisplayTitle: string;
  bankDisplayTitle: string;
  isActive: boolean;
  currency: string;
  fattyPaymentMethodCodes: string[];
};

type Bank = {
  bankName: string;
  bankCode: string;
  providerBankCode: string;
  displayTitle: string;
  isActive: boolean;
  updatedOn: string;
  updatedBy: string;
  fattyPaymentMethodCodes: string[];
  currency: string;
  transactionType: string;
  paymentMethodCode: string;
  paymentMethodRow: PaymentMethod;
};
type PaymentMethod = {
  key: string;
  hasBank: boolean;
  transactionType: string;
  paymentMethodName: string;
  paymentMethodCode: string;
  displayTitle: string;
  currency: string;
  isActive: boolean;
  updatedOn: string;
  updatedBy: string;
  bankList: Bank[];
};

type BankQueryRequest = {
  fattyPaymentMethod: string;
  currency: string;
  transactionType: string;
  serviceProviderPaymentMethod: string;
};

const relatedPaymentMethodsRows = reactive<RelatedPaymentMethod[]>([]);
const relatedPaymentMethodColumns = [
  {
    name: 'paymentMethod',
    label: 'Payment Method',
    field: 'paymentMethodDisplayTitle',
    align: 'left' as const
  },
  {
    name: 'bank',
    label: 'Bank',
    field: 'bankDisplayTitle',
    align: 'left' as const
  }
];

const bankColumns = [
  {
    name: 'bank',
    label: 'Bank',
    field: 'displayTitle',
    align: 'left' as const
  },
  {
    name: 'updatedOn',
    label: 'Last Update Time (GMT+0)',
    field: 'updatedOn',
    align: 'left' as const
  },
  {
    name: 'updatedBy',
    label: 'Updated By',
    field: 'updatedBy',
    align: 'left' as const
  }
];
const paymentMethodsRows = reactive<PaymentMethod[]>([]);
const paymentMethodColumns = [
  {
    name: 'currency',
    label: 'Currency',
    field: 'currency',
    align: 'left' as const
  },
  {
    name: 'transactionType',
    label: 'Type',
    field: 'transactionType',
    align: 'left' as const
  },
  {
    name: 'paymentMethod',
    label: 'Payment Method',
    field: 'displayTitle',
    align: 'left' as const
  },
  {
    name: 'updatedOn',
    label: 'Last Update Time (GMT+0)',
    field: 'updatedOn',
    align: 'left' as const
  },
  {
    name: 'updatedBy',
    label: 'Updated By',
    field: 'updatedBy',
    align: 'left' as const
  }
];

const refreshPaymentMethodList = async () => {
  const response = await paymentStatusApi.getPaymentMethodStatus();
  paymentMethodsRows.length = 0;
  response.data.sort((a: PaymentMethod, b: PaymentMethod) =>
    a.currency.localeCompare(b.currency) !== 0
      ? a.currency.localeCompare(b.currency)
      : a.paymentMethodName.localeCompare(b.paymentMethodName)
  );
  response.data.forEach((pm: PaymentMethod) => {
    pm.key = pm.transactionType + pm.paymentMethodName + pm.currency + pm.paymentMethodCode;
    pm.displayTitle =
      pm.paymentMethodName + (pm.paymentMethodCode ? ` (${pm.paymentMethodCode})` : '');
    paymentMethodsRows.push(pm);
  });
};
const changeBankStatusFromPage = async (bank: any) => {
  if (bank) {
    getRelatedPaymentMethodByBank(bank);
    await changeBankStatus(bank);
    showSnackBar('success', 'Update status successfully');
  }
};
const changeBankStatusFromPopup = async (bank: any) => {
  if (bank) {
    await changeBankStatus(bank);
    showSnackBar('success', 'Update status successfully');
  }
};

const changeBankStatus = async (bank: any) => {
  const promiseArray: any[] = [];
  bank.fattyPaymentMethodCodes.forEach((fattyPaymentMethodCode: string) => {
    promiseArray.push(
      paymentStatusApi.patchPaymentSetting({
        BankCodes: [bank.bankCode],
        Currency: bank.currency,
        Status: bank.isActive ? 1 : 2,
        FattyPaymentMethodCode: fattyPaymentMethodCode
      })
    );
  });
  await Promise.all(promiseArray);

  if (bank.paymentMethodRow.isActive && bank.paymentMethodRow.bankList.every((b: any) => !b.isActive)) {
    await turnOffPaymentMethodStatus(bank.paymentMethodRow);
  } else if (!bank.paymentMethodRow.isActive && bank.paymentMethodRow.bankList.some((b: any) => b.isActive)) {
    await turnOnPaymentMethodStatus(bank.paymentMethodRow);
  }
};

const getRelatedPaymentMethodByBank = async (bank: any) => {
  var params = new URLSearchParams();
  params.append('Currency', bank.currency);
  params.append('FattyBankCode', bank.bankCode);
  bank.fattyPaymentMethodCodes.forEach((fattyPaymentMethodCode: string) => {
    params.append('FattyPaymentMethodCodes', fattyPaymentMethodCode);
  });
  var data = {
    params: params
  };
  const response = await paymentStatusApi.getRelatedPaymentMethodByBank(data);
  relatedPaymentMethodsRows.length = 0;

  if (response.data.length > 0) {
    response.data.forEach((rpm: RelatedPaymentMethod) => {
      rpm.fattyPaymentMethodCodes = [rpm.paymentMethodName];
      rpm.paymentMethodDisplayTitle =
        rpm.paymentMethodName +
        (rpm.providerPaymentMethodCode ? ` (${rpm.providerPaymentMethodCode})` : '');
      rpm.bankDisplayTitle = rpm.bankName + ' ' + `(${rpm.bankCode})`;

      relatedPaymentMethodsRows.push(rpm);
    });
    ShowRelatedPaymentMethod.value = true;
  }
};

const changePaymentMethodStatus = async (setting: Partial<PaymentMethod>) => {
  await paymentStatusApi.patchPaymentSetting({
    Currency: setting.currency,
    TransactionType: setting.transactionType,
    FattyPaymentMethodCode: setting.paymentMethodName,
    PaymentMethodCode: setting.paymentMethodCode,
    Status: setting.isActive ? 1 : 2
  });
  showSnackBar('success', 'Update status successfully');
  await refreshPaymentMethodList();
};

const turnOnPaymentMethodStatus = async (setting: Partial<PaymentMethod>) => {
  await paymentStatusApi.patchPaymentSetting({
    Currency: setting.currency,
    TransactionType: setting.transactionType,
    FattyPaymentMethodCode: setting.paymentMethodName,
    PaymentMethodCode: setting.paymentMethodCode,
    Status: 1
  });
  await refreshPaymentMethodList();
};

const turnOffPaymentMethodStatus = async (setting: Partial<PaymentMethod>) => {
  await paymentStatusApi.patchPaymentSetting({
    Currency: setting.currency,
    TransactionType: setting.transactionType,
    FattyPaymentMethodCode: setting.paymentMethodName,
    PaymentMethodCode: setting.paymentMethodCode,
    Status: 2
  });
  await refreshPaymentMethodList();
};

const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  router.push('/login');
};

const refreshBankList = async (request: BankQueryRequest, paymentMethodRow: PaymentMethod) => {
  const data = {
    PaymentMethod: request.fattyPaymentMethod,
    Currency: request.currency,
    TransactionType: request.transactionType,
    ServiceProviderPaymentMethod: request.serviceProviderPaymentMethod
  };
  const respones = await paymentStatusApi.getPaymentMethodBank(data);
  if (paymentMethodRow.bankList) {
    paymentMethodRow.bankList.length = 0;
  }
  paymentMethodRow.bankList = respones.data.map((d: any) => ({
    transactionType: paymentMethodRow.transactionType,
    bankCode: d.fattyBankCode,
    bankName: d.bankName,
    providerBankCode: d.providerBankCode,
    displayTitle: `${d.bankName} (${d.providerBankCode})`,
    isActive: d.isActive,
    updatedOn: d.updatedOn,
    updatedBy: d.updatedBy,
    currency: d.currency,
    fattyPaymentMethodCodes: d.fattyPaymentMethodCodes,
    paymentMethodCode: request.serviceProviderPaymentMethod,
    paymentMethodRow: paymentMethodRow
  }));
};
const getBankByPaymentMethod = async (prop: any) => {
  prop.expand = !prop.expand;
  // update every time
  // if (prop.row.bankList && prop.row.bankList.length > 0) {
  //   return;
  // }
  const request: BankQueryRequest = {
    fattyPaymentMethod: prop.row.paymentMethodName,
    currency: prop.row.currency,
    transactionType: prop.row.transactionType,
    serviceProviderPaymentMethod: prop.row.paymentMethodCode
  };
  await refreshBankList(request, prop.row);
};
refreshPaymentMethodList();
</script>

<template>
  <q-layout view="lHh Lpr lff" style="min-height: 85vh">
    <q-header reveal class="bg-brand-color">
      <q-toolbar>
        <q-space />
        <q-btn flat round dense icon="logout" @click="logout" />
      </q-toolbar>
    </q-header>

    <q-page-container>
      <q-page style="min-height: 80vh">
        <h4>{{ title }}</h4>
        <div class="q-pa-md flex justify-center container">
          <div class="q-gutter-y-md fit">
            <q-card>
              <q-tabs
                v-model="tab"
                dense
                class="text-grey"
                active-color="primary"
                indicator-color="primary"
                align="justify"
                narrow-indicator
              >
                <q-tab name="payment-methods" label="payment methods" />
              </q-tabs>

              <q-separator />

              <q-tab-panels v-model="tab">
                <q-tab-panel name="payment-methods">
                  <q-table
                    hide-bottom
                    row-key="key"
                    :rows="paymentMethodsRows"
                    :columns="paymentMethodColumns"
                    :pagination="{ rowsPerPage: 0 }"
                  >
                    <template v-slot:header="props">
                      <q-tr :props="props">
                        <q-th auto-width></q-th>
                        <q-th auto-width>Status</q-th>

                        <q-th v-for="col in props.cols" :key="col.name" :props="props">{{
                          col.label
                        }}</q-th>
                      </q-tr>
                    </template>
                    <template v-slot:body="prop">
                      <q-tr :props="prop" :key="`m_${prop.row.index}`">
                        <q-td auto-width>
                          <q-btn
                            size="sm"
                            color="blue-9"
                            dense
                            v-show="prop.row.hasBank"
                            @click="getBankByPaymentMethod(prop)"
                            :icon="prop.expand ? 'remove' : 'add'"
                          >
                          </q-btn>
                        </q-td>
                        <q-td auto-width>
                          <q-toggle
                            v-model="prop.row.isActive"
                            checked-icon="check"
                            color="green"
                            unchecked-icon="clear"
                            @update:model-value="changePaymentMethodStatus(prop.row)"
                          />
                        </q-td>

                        <q-td v-for="col in prop.cols" :key="col.name" :props="prop">{{
                          col.value
                        }}</q-td>
                      </q-tr>
                      <q-tr v-show="prop.expand" :props="prop">
                        <q-td colspan="100%">
                          <div class="text-left">
                            <q-table
                              hide-bottom
                              row-key="bankName"
                              :rows="prop.row.bankList"
                              :columns="bankColumns"
                              :pagination="{ rowsPerPage: 0 }"
                            >
                              <template v-slot:header="props">
                                <q-tr :props="props">
                                  <q-th auto-width>Status</q-th>
                                  <q-th v-for="col in props.cols" :key="col.name" :props="props">{{
                                    col.label
                                  }}</q-th>
                                </q-tr>
                              </template>
                              <template v-slot:body="props">
                                <q-tr :props="props" :key="`m_${props.row.index}`">
                                  <q-td auto-width>
                                    <q-toggle
                                      v-model="props.row.isActive"
                                      checked-icon="check"
                                      color="green"
                                      unchecked-icon="clear"
                                      @update:model-value="changeBankStatusFromPage(props.row)"
                                    />
                                  </q-td>
                                  <q-td v-for="col in props.cols" :key="col.name" :props="props">{{
                                    col.value
                                  }}</q-td>
                                </q-tr>
                              </template>
                            </q-table>
                          </div>
                        </q-td>
                      </q-tr>
                    </template>
                  </q-table>
                </q-tab-panel>
              </q-tab-panels>
            </q-card>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>

  <div class="q-pa-md q-gutter-sm">
    <q-dialog v-model="ShowRelatedPaymentMethod">
      <q-card style="width: 700px; max-width: 80vw">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Payment Methods Related To The Same Bank</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-table
          hide-bottom
          row-key="paymentMethodName"
          :rows="relatedPaymentMethodsRows"
          :columns="relatedPaymentMethodColumns"
          :pagination="{ rowsPerPage: 0 }"
        >
          <template v-slot:header="props">
            <q-tr :props="props">
              <q-th auto-width>Status</q-th>

              <q-th v-for="col in props.cols" :key="col.name" :props="props">{{ col.label }}</q-th>
            </q-tr>
          </template>
          <template v-slot:body="prop">
            <q-tr :props="prop" :key="`m_${prop.row.index}`">
              <q-td auto-width>
                <q-toggle
                  v-model="prop.row.isActive"
                  checked-icon="check"
                  color="green"
                  unchecked-icon="clear"
                  @update:model-value="changeBankStatusFromPopup(prop.row)"
                />
              </q-td>

              <q-td v-for="col in prop.cols" :key="col.name" :props="prop">{{ col.value }}</q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card>
    </q-dialog>
  </div>
</template>

<style scoped>
.container {
  margin: 0 auto;
  width: 1200px;
}

.bg-brand-color {
  background: #3b6a46 !important;
}
</style>
